import "./Goleo.css";

const Goleo = ({ goleos, themeColor }) => {
  return (
    <div className="goleo-table">
      {/* Men’s Goleo */}
      {goleos.men.length > 0 && (
        <GoleoTable
          title="Goleo Hombres"
          players={goleos.men}
          headerLabels={["P", "JUGADOR", "EQUIPO", "G"]}
          themeColor={themeColor}
        />
      )}

      {/* Women’s Goleo */}
      {goleos.women.length > 0 && (
        <GoleoTable
          title="Goleo Mujeres"
          players={goleos.women}
          headerLabels={["P", "JUGADORA", "EQUIPO", "G"]}
          themeColor={themeColor}
        />
      )}
    </div>
  );
};

const GoleoTable = ({
  title,
  players,
  leagueFilter,
  headerLabels,
  themeColor,
}) => (
  <>
    <div className="goleo-title">{title}</div>
    {/* Table Headers */}
    <GoleoPlayer
      key={title}
      pos={headerLabels[0]}
      name={headerLabels[1]}
      team={headerLabels[2]}
      goals={headerLabels[3]}
      leagueFilter={leagueFilter}
      themeColor={themeColor}
    />
    {/* Player Rows */}
    {players.map((player, index) => (
      <GoleoPlayer
        key={player.player_id} // Use player_id as the unique key
        pos={index + 1}
        name={player.player_name}
        team={player.team_name}
        goals={player.goals}
        leagueFilter={leagueFilter}
        themeColor={themeColor}
      />
    ))}
  </>
);

const GoleoPlayer = ({ pos, name, team, goals, themeColor }) => {
  return (
    <div className="goleo-row">
      <div className={`goleo-column position`}>
        <div className="goleo-container position">
          <div className="goleo-item position">{pos}</div>
        </div>
      </div>
      <div
        className={`goleo-column name`}
        style={{ backgroundImage: `linear-gradient(${themeColor}, #0f112a)` }}
      >
        <div className="goleo-container name">
          <div className="goleo-item name">{name}</div>
        </div>
      </div>
      <div
        className={`goleo-column team`}
        style={{ backgroundImage: `linear-gradient(${themeColor}, #0f112a)` }}
      >
        <div className="goleo-container name">
          <div className="goleo-item name">{team}</div>
        </div>
      </div>
      <div
        className={`goleo-column goals`}
        style={{ backgroundImage: `linear-gradient(${themeColor}, #0f112a)` }}
      >
        <div className="goleo-container goals">
          <div className="goleo-item goals">{goals}</div>
        </div>
      </div>
    </div>
  );
};

export default Goleo;
