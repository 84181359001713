import React, { useEffect, useState } from "react";
import MyPageBanner from "../components/MyPageBanner";
import MyStandingsTable from "../components/MyStandingsTable";
import Partidos from "../components/Partidos";
import Goleo from "../components/Goleo";
import axios from "axios";
import "./DynamicLeaguePage.css";

const DynamicLeaguePage = ({
  bannerImage,
  bannerText,
  leagueName,
  themeColor = "#121b69",
  trackGoals,
  trackPlayers,
}) => {
  const [standings, setStandings] = useState([]);
  const [goleos, setGoleos] = useState({ men: [], women: [] });
  const [gamesByPeriod, setGamesByPeriod] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLeagueData = async () => {
      try {
        const requests = [
          axios.get(
            `https://canutillofut7.com/api/leagues/${leagueName}/standings`
          ),
        ];

        if (trackGoals === 1) {
          requests.push(
            axios.get(
              `https://canutillofut7.com/api/leagues/${leagueName}/goleo`
            )
          );
        }

        const [standingsResponse, goleosResponse] = await Promise.all(requests);

        setStandings(standingsResponse.data);

        if (trackGoals && goleosResponse) {
          setGoleos({
            men: goleosResponse.data.men || [],
            women: goleosResponse.data.women || [],
          });
        }

        setLoading(false);
      } catch (err) {
        setError("Failed to load league data.");
        setLoading(false);
      }
    };

    fetchLeagueData();
  }, [leagueName, trackGoals]);

  return (
    <>
      <MyPageBanner imageUrl={bannerImage} text={bannerText} />
      <div className="layout">
        <div className="left">
          <MyStandingsTable
            standings={standings}
            leagueName={leagueName}
            themeColor={themeColor}
          />
          {trackGoals === 1 && (
            <Goleo goleos={goleos} themeColor={themeColor} />
          )}
        </div>
        <div className="right">
          <Partidos leagueFilter={leagueName} themeColor={themeColor} />
        </div>
      </div>
    </>
  );
};

export default DynamicLeaguePage;
