import React from "react";
import "./TeamStanding.css";

// Accept props as an argument
const TeamStanding = (props) => {
  function adjustBrightness(hex, factor) {
    const rgb = parseInt(hex.slice(1), 16);
    let r = Math.min(255, Math.max(0, ((rgb >> 16) & 0xff) * factor));
    let g = Math.min(255, Math.max(0, ((rgb >> 8) & 0xff) * factor));
    let b = Math.min(255, Math.max(0, (rgb & 0xff) * factor));

    // Round each color component and ensure they are two-digit hex values
    r = Math.round(r).toString(16).padStart(2, "0");
    g = Math.round(g).toString(16).padStart(2, "0");
    b = Math.round(b).toString(16).padStart(2, "0");

    // Concatenate to form a valid hex color
    return `#${r}${g}${b}`;
  }

  function washOutColor(hex, intensity = 0.2) {
    const rgb = parseInt(hex.slice(1), 16);

    // Extract individual RGB components
    let r = (rgb >> 16) & 0xff;
    let g = (rgb >> 8) & 0xff;
    let b = rgb & 0xff;

    // Blend each component with white (255) based on the intensity
    r = Math.round(r + (255 - r) * intensity)
      .toString(16)
      .padStart(2, "0");
    g = Math.round(g + (255 - g) * intensity)
      .toString(16)
      .padStart(2, "0");
    b = Math.round(b + (255 - b) * intensity)
      .toString(16)
      .padStart(2, "0");

    // Concatenate to form a valid hex color
    return `#${r}${g}${b}`;
  }

  const secondaryColor = adjustBrightness(props.themeColor, 0.4) || "#000000";

  const tertiaryColor = washOutColor(props.themeColor, 0.9) || "#000000"; // Ensure fallback

  return (
    <div
      className="team-row"
      style={{
        "--theme-color": `${props.themeColor}`,
        "--secondary-color": `${secondaryColor}`,
        "--tertiary-color": `${tertiaryColor}`,
      }}
    >
      <div className={"team-column position "}>
        <div className="team-container position">
          <div className="team-item position">{props.position}</div>
        </div>
      </div>
      <div className={"team-column teamname "}>
        <div className="team-container teamname">
          <div className="team-item teamname">{props.teamName}</div>
        </div>
      </div>
      <div className={"team-column games"}>
        <div className="team-container games">
          <div className={"team-item data odd "}>{props.played}</div>
          <div className="team-item data">{props.wins}</div>
          <div className={"team-item data odd "}>{props.draws}</div>
          <div className="team-item data">{props.losses}</div>
        </div>
      </div>
      <div className={"team-column goals "}>
        <div className="team-container goals">
          <div className={"team-item data odd "}>{props.goalsFor}</div>
          <div className="team-item data">{props.goalsAgainst}</div>
          <div className={"team-item data odd "}>{props.goalDifference}</div>
        </div>
      </div>
      <div className={"team-column points "}>
        <div className="team-container points">
          <div className="team-item points">{props.points}</div>
        </div>
      </div>
    </div>
  );
};

export default TeamStanding;
