import React, { useState, useEffect } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../images/futlogo.png";

import "./MyNavbar.css";

const MyNavbar = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > lastScrollY && currentScrollY > 50) {
        // Scrolling down
        setIsVisible(false);
      } else {
        // Scrolling up
        setIsVisible(true);
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollY]);

  return (
    <>
      <Navbar
        bg="light"
        className={`navbar fixed-top ${
          isVisible ? "navbar-visible" : "navbar-hidden"
        }`}
      >
        <Nav className="w-100 d-flex align-items-center justify-items-center">
          {/* Logo Section */}
          <Nav.Link as={Link} to="/" className="logo-container">
            <img src={logo} alt="canutillo" className="navbar-logo" />
          </Nav.Link>

          {/* Spacer for Gap */}
          <div className="flex-grow-.2"></div>

          {/* Links Section */}
          <Nav className="d-flex align-items-center">
            <Nav.Link as={Link} to="/ligas" className="nav-item">
              <h2>Ligas</h2>
            </Nav.Link>
            <Nav.Link as={Link} to="/partidos" className="nav-item">
              <h2>Partidos</h2>
            </Nav.Link>
          </Nav>
        </Nav>
      </Navbar>

      {/* Content Spacer */}
      <div style={{ marginTop: "70px" }}></div>
    </>
  );
};

export default MyNavbar;
