import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./MyLigasOptions.css";

const MyLigasOptions = () => {
  const [leagues, setLeagues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLeagues = async () => {
      try {
        const response = await axios.get(
          "https://canutillofut7.com/api/leagues"
        ); // Ensure this points to the correct backend
        setLeagues(response.data);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching leagues:", err);
        setError("Failed to fetch leagues");
        setLoading(false);
      }
    };

    fetchLeagues();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="menu-container">
      {leagues.map((league) => (
        <Link
          key={league.league_id}
          to={`/ligas/${league.league_id}`}
          className="menu-item"
        >
          {league.league_name} - {league.season}
          <span className="arrow">{">"}</span>
        </Link>
      ))}
    </div>
  );
};

export default MyLigasOptions;
